import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Tooltip,
  MenuItem,
  CssBaseline,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import BookIcon from '@mui/icons-material/Book';

import InventoryIcon from '@mui/icons-material/Inventory';
import PaidIcon from '@mui/icons-material/Paid';
import SettingsIcon from '@mui/icons-material/Settings';
import QrCodeIcon from '@mui/icons-material/QrCode';
import RequestPageIcon from '@mui/icons-material/RequestPage';

import MenuIcon from '@mui/icons-material/Menu';

import { useAuth } from 'provider/authProvider';

export default function Navbar(props) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const settings = [{
    name: 'Profile',
    func: 'profile'
  },
  {
    name: 'Dashboard',
    func: 'dashboard'
  },
  {
    name: 'Logout',
    func: 'logout'
  }];

  const organizerPages = [{
    to: 'events',
    name: 'Events',
    icon: <EventIcon />
  }, {
    to: 'bookings',
    name: 'Bookings',
    icon: <BookIcon />
  }, {
    to: 'transactions',
    name: 'Transactions',
    icon: <PaidIcon />
  }, {
    to: 'qr_scanner',
    href: '/qr_scanner',
    name: 'QR Code Scanner',
    icon: <QrCodeIcon />
  }
  ];

  const adminPages = [{
    to: 'events',
    name: 'Events',
    icon: <EventIcon />
  }, {
    to: 'bookings',
    name: 'Bookings',
    icon: <BookIcon />
  }, {
    to: 'transactions',
    name: 'Transactions',
    icon: <PaidIcon />
  }, {
    to: 'requests',
    name: 'Requests',
    icon: <RequestPageIcon />
  }, {
    to: 'organizers',
    name: 'Organizers',
    icon: <InventoryIcon />
  }, {
    to: 'settings',
    name: 'Settings',
    icon: <SettingsIcon />
  }]
  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserMenuClick = (e, func) => {
    handleCloseUserMenu(e);
    switch (func) {
      case 'profile':
        window.location.href = '/profile';
        break;
      case 'dashboard':
        navigate('dashboard');
        break;
      case 'logout':
        logout();
        break;
      default: break;
    }
  }
  const drawer = (
    <div>
      <Divider />
      <List>
        <ListItem component="a" onClick={() => navigate('dashboard')} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <Divider />
        {user.permissions.includes('organizer') && organizerPages.map((page, index) => (
          <ListItem key={index} component="a" onClick={page.href ? () => navigate('/qr_scanner') : () => navigate(page.to)} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {page.icon}
              </ListItemIcon>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {user.permissions.includes('admin') && adminPages.map((page, index) => (
          <ListItem key={index} component="a" onClick={() => navigate(page.to)} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {page.icon}
              </ListItemIcon>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
  const drawerWidth = 240;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ background: '#c51047' }}
      >
        <Box sx={{ marginX: '1em' }}>
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <a href='/' className='flex flex-grow'>
              {/* <img alt='' src={logo2Icon} width={'100px'}/> */}
            </a>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Account">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user-round-cog"><path d="M2 21a8 8 0 0 1 10.434-7.62" /><circle cx="10" cy="8" r="5" /><circle cx="18" cy="18" r="3" /><path d="m19.5 14.3-.4.9" /><path d="m16.9 20.8-.4.9" /><path d="m21.7 19.5-.9-.4" /><path d="m15.2 16.9-.9-.4" /><path d="m21.7 16.5-.9.4" /><path d="m15.2 19.1-.9.4" /><path d="m19.5 21.7-.4-.9" /><path d="m16.9 15.2-.4-.9" /></svg>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={index} onClick={(e) => handleUserMenuClick(e, setting.func)}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { marginTop: '65px', boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ backgroundColor: '#f5f6fa', flexGrow: 1, pt: 10, minHeight: '100vh', width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {props.children}
      </Box>
    </Box>
  );
}