import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";


import { AdminLayout, OrganizerLayout } from 'layout';

import routes from "routes";

import Authentication from "./authentication";
import Authorization from "./authorization";
import PERMISSIONS from "permissions/permissions";

import 'assets/css/main.scss'

const RoutePath = () => {
  return (
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route
            path='profile'
            element={
              <Authentication>
              </Authentication>
            }
          />
          <Route
            element={<Authorization permissions={[PERMISSIONS.ADMIN]} />}
          >
            <Route path="/admin/*" element={<AdminLayout />} />
          </Route>
          <Route
            element={<Authorization permissions={[PERMISSIONS.ORGANIZER]} />}
          >
            <Route path="/organizer/*" element={<OrganizerLayout />} />
          </Route>
        </Routes>
      </Suspense>
  );
};

export default RoutePath;
