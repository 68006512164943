import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const NotFound = React.lazy(() => import("pages/notfound"));

const routes = [
  { path: "/", exact: true, name: "Home", element: Home },
  { path: "/*", name: "NotFound", element: NotFound },
];

const authRoutes = [
]

const organizerRoutes = [
];

const adminRoutes = [
]

export default routes;
export { authRoutes, adminRoutes, organizerRoutes };