import { useState, useRef, useCallback, useEffect } from "react";

export default function InfiniteLooper({ speed, direction, children }) {
    const [looperInstances, setLooperInstances] = useState(8);
    const outerRef = useRef(null);
    const innerRef = useRef(null);

    const setupInstances = useCallback(() => {
        if (!innerRef.current || !outerRef.current) return;

        const { width } = innerRef.current.getBoundingClientRect();
        const { width: parentWidth } = outerRef.current.getBoundingClientRect();

        // Calculate instance width based on children
        const instanceWidth = width / innerRef.current.children.length;

        // Prevent negative or zero instances
        if (instanceWidth <= 0) return;

        // Calculate the required number of instances
        const requiredInstances = Math.ceil(parentWidth / instanceWidth);
        
        // Update state only if the new value is greater than current
        if (requiredInstances > looperInstances) {
            setLooperInstances(requiredInstances);
        }
    }, [looperInstances]);

    useEffect(() => {
        setupInstances();
        window.addEventListener("resize", setupInstances); // Recalculate on resize
        return () => window.removeEventListener("resize", setupInstances); // Cleanup
    }, [setupInstances]);

    return (
        <div className="looper" ref={outerRef}>
            <div className="looper__innerList" ref={innerRef}>
                {[...Array(looperInstances)].map((_, ind) => (
                    <div
                        key={ind}
                        className="looper__listInstance"
                        style={{
                            animationDuration: `${speed}s`,
                            animationDirection: direction === "right" ? "reverse" : "normal",
                        }}
                    >
                        {children}
                    </div>
                ))}
            </div>
        </div>
    );
}