import React from "react";
import {
  Navigate,
  useLocation,
  // Outlet
} from "react-router-dom";

export default function Unauthorized() {
  const location = useLocation();
  return <Navigate to='/' state={{ path: location.pathname }} replace />;
};